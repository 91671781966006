<template>
  <div class="w-100 d-flex flex-column h-100">
    <BaseToolBar title="Coordonnées" subtitle="Emails, numéros de téléphone"></BaseToolBar>
    <base-form class="mr-md-3" @click="updateCollaborateur" :loading="loading" shadow>
      <base-form-group title="Email" dense icon="mail">
        <base-form-row>
          <BaseInput
            v-model="collaborateur_email_pro"
            inputText="Email pro"
            :errors="feedback.collaborateur_email_pro"
            :iconActive="true"
          ></BaseInput>
          <BaseInput
            v-model="collaborateur_email_perso"
            inputText="Email perso"
            :errors="feedback.collaborateur_email_perso"
            :iconActive="true"
          ></BaseInput>
        </base-form-row>
      </base-form-group>
      <base-form-group title="Téléphones" dense icon="phone">
        <base-form-row>
          <BaseInput
            v-model="collaborateur_tel_1"
            inputText="Téléphone pro"
            :errors="feedback.collaborateur_tel_1"
            :iconActive="true"
          ></BaseInput>
          <BaseInput
            v-model="collaborateur_tel_2"
            inputText="Téléphone perso"
            :errors="feedback.collaborateur_tel_2"
            :iconActive="true"
          ></BaseInput>
        </base-form-row>
        <base-form-row>
          <BaseInput
            v-model="collaborateur_tel_3"
            inputText="N° de poste"
            :errors="feedback.collaborateur_tel_3"
            :iconActive="true"
          ></BaseInput>
        </base-form-row>
      </base-form-group>
      <base-form-group title="Adresse" dense icon="map">
        <base-form-row row>
          <base-input-area v-model="collaborateur_adresse" text="Adresse postale"></base-input-area>
        </base-form-row>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
import BaseInput from "@/components/bases/Input.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";

export default {
  components: {
    BaseInput,
    BaseForm,
    BaseFormGroup,
    BaseFormRow,
    BaseToolBar,
    BaseInputArea
  },
  data() {
    return {
      feedback: {},
      lists: {},
      loading: false
    };
  },
  props: {
    collaborateur: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    contextData: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  },
  methods: {
    ...mapActions({
      _updateCollaborateur: "collaborateurs/updateCollaborateur"
    }),
    updateCollaborateur: function() {
      this.feedback = {};
      this.loading = true;
      this._updateCollaborateur()
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  },
  computed: {
    ...mapFields("collaborateurs", [
      "collaborateur.collaborateur_email_pro",
      "collaborateur.collaborateur_email_perso",
      "collaborateur.collaborateur_tel_1",
      "collaborateur.collaborateur_tel_2",
      "collaborateur.collaborateur_tel_3",
      "collaborateur.collaborateur_adresse"
    ])
  }
};
</script>
<style lang="css">
</style>